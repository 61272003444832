<template>
  <div
    class="breadcrumb"
    aria-label="breadcrumbs"
  >
    <ul>
      <li><a @click="emit('page', 'start')">Inhoudsopgave</a></li>
      <li class="is-active">
        <a>Materialen</a>
      </li>
    </ul>
  </div>

  <h3 class="title is-3">
    Materialen
  </h3>
    
  <h4 class="title is-4">
    Blusmiddelen
  </h4>
  <p class="block">
    De locaties van de brandblusvoorziening zijn vastgelegd op de <a href="https://rphwaalwijk.sharepoint.com/sites/KVGMPubliek/SitePages/BHV-Basisgegevens.aspx#vluchtplantekeningen">plattegronden</a>.<br>
    De brandblusvoorzieningen bestaan uit brandslanghaspels, schuimblussers, poederblusser en CO2- blusser. De voorzieningen worden jaarlijks gekeurd. In onderstaande tabel is per locatie aangegeven, waar de brandblusvoorzieningen zich bevinden. 
  </p>

  <table class="table is-fullwidth">
    <thead>
      <tr>
        <th>Brandblusvoorzieningen</th>
        <th>Zinkerweg 13a</th>
        <th>Havenweg 7</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Brandslanghasper</td>
        <td>
          Magazijn,voorzijde<br>
          Magazijn, achterzijde
        </td>
        <td>
          Magazijn,voorzijde<br>
          Magazijn, achterzijde
        </td>
      </tr>
      <tr>
        <td>Schuimblusser</td>
        <td>
          Magazijn, voorzijde<br>
          Assemblage<br>
          Kantoor begane grond<br>
          1e verdieping, nabij vergaderruimte<br>
          1e verdieping, nabij printer<br>
          1e verdieping, nabij klimaatkamer
        </td>
        <td>
          Magazijn, voorzijde<br>
          Magazijn, avulruimte<br>
          Magazijn, entresol<br>
          Hal kantoor begane grond<br>
          Hal kantoor 1e verdieping
        </td>
      </tr>
      <tr>
        <td>
          CO2-blusser
        </td>
        <td>
          1e verdieping, kantoren achterzijde	
        </td>
        <td />
      </tr>
      <tr>
        <td>
          Poederblusser
        </td>
        <td>
          Magazijn, nabij sanitaire ruimte
        </td>
        <td />
      </tr>
    </tbody>
  </table>

  <h4 class="title is-4">
    Eerste Hulpmiddelen
  </h4>
  <p class="block">
    De locaties van de Eerste Hulpmiddelen zijn vastgelegd op de <a href="https://rphwaalwijk.sharepoint.com/sites/KVGMPubliek/SitePages/BHV-Basisgegevens.aspx#vluchtplantekeningen">plattegronden</a>. De Eerste Hulpmiddelen worden jaarlijks gecontroleerd op volledigheid en bruikbaarheid. Geconstateerde afwijkingen worden terstond verholpen. In onderstaande tabel is per locatie aangegeven waar de Eerste Hulpmiddelen zich bevinden.
  </p>
  <table class="table is-fullwidth">
    <thead>
      <tr>
        <th>Eerste Hulpmiddel</th>
        <th>Zinkerweg 13a</th>
        <th>Havenweg 7</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>AED</td>
        <td />
        <td>Magazijn, achterzijde, bij roldeur.</td>
      </tr>
      <tr>
        <td>EHBO-trommel</td>
        <td>Sanitaire ruimte naast werkplaats</td>
        <td>Magazijn, tegen de buitenmuur</td>
      </tr>
      <tr>
        <td>Pleisterautomaat</td>
        <td>Kantine</td>
        <td>Magazijn boven de wasbak</td>
      </tr>
      <tr>
        <td>Oogdouche</td>
        <td>Sanitaire ruimte naast werkplaats</td>
        <td>
          Magazijn, tegen de buitenmuur<br>
          Magazijn, vulruimte
        </td>
      </tr>
    </tbody>
  </table>

  <h4 class="title is-4">
    Alarmering
  </h4>
  <p class="block">
    Om de medewerkers en gasten bij RPH te alarmeren en een ontruiming tot stand te brengen, is op de Zinkerweg 13a een sirene aanwezig (slow-whoop installatie). De locaties van de melders zijn aangegeven op de <a href="https://rphwaalwijk.sharepoint.com/sites/KVGMPubliek/SitePages/BHV-Basisgegevens.aspx#vluchtplantekeningen">vluchtplantekeningen</a>.
  </p>
  <p class="block">
    Op de Havenweg 7 vindt alarmering plaats door middel van bellen en/of elkaar aanspreken. 
  </p>
  <p class="block">
    In iedere ruimte hangen instructiekaarten, waarop is aangegeven hoe te handelen bij een brand, ongeval of ontruiming. 
  </p>
</template>

<script setup>
const emit = defineEmits(['page'])
</script>

<style scoped>
img {
    width: 100%;
    max-height: 25vh;
    object-fit: contain;
}
</style>