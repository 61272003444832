<template>
  <div
    class="breadcrumb"
    aria-label="breadcrumbs"
  >
    <ul>
      <li><a @click="emit('page', 'start')">Inhoudsopgave</a></li>
      <li class="is-active">
        <a>Rapportages</a>
      </li>
    </ul>
  </div>

  <h3 class="title is-3">
    Rapportages
  </h3>
    
  <h4 class="title is-4">
    Registratie van een incident
  </h4>
  <p class="block">
    De KVGM Coördinator registreert ieder incident conform het BZS, zie het <a href="https://rphwaalwijk.sharepoint.com/:f:/r/sites/KVGM-Welker/Gedeelde%20documenten/0.%20KVGM%202020%20-%20VCA/12.%20Ongevallen%20en%20Incidenten?csf=1&web=1&e=Txc6ap">incidenter register</a>. 
  </p>
    
  <h4 class="title is-4">
    Registratie van BHV-oefening
  </h4>
  <p class="block">
    BHV-oefeningen worden geregistreerd conform het BZS, zie de <a href="https://rphwaalwijk.sharepoint.com/sites/KVGM-Welker/Gedeelde%20documenten/Forms/AllItems.aspx?csf=1&web=1&e=Txc6ap&cid=6069c99d-3db6-4fd6-8a97-010ee051b986&FolderCTID=0x01200041C36AAE9B6AC14CB81907814D3EECB8&viewid=b5f98b48-5676-4d1b-8b79-0e09fb73ddec&id=/sites/KVGM-Welker/Gedeelde%20documenten/0.%20KVGM%202020%20-%20VCA/07.%20Noodsituaties/02.%20BHV%20Oefeningen">BHV Oefeningen</a>.
  </p>
    
  <h4 class="title is-4">
    Evaluatie en rapportage
  </h4>
  <p class="block">
    In de KVGM-rapportage aan het MT worden elk kwartaal incidenten gerapporteerd en mogelijke verbetervoorstellen gedaan. Periodiek is de KVGM Coördinator aanwezig bij het MT-overleg. 
  </p>
    
  <h4 class="title is-4">
    Verspreiding BHV-plan
  </h4>
  <p class="block">
    Verspreiding van het BHV-plan dient gecontroleerd plaats te vinden om er zeker van te zijn dat alle ontvangers en locaties steeds over de laatste versie beschikken. Onderstaand de personen en locaties die beschikken over een hardcopy van het BHV-plan.
  </p>
  <div class="content block">
    <ul>
      <li>BHV-coördinator </li>
      <li>Plaatsvervangend BHV-coördinator</li>
      <li>Kantoor begane grond Zinkerweg 13a </li>
      <li>Kantoor begane grond Havenweg 7</li>
    </ul>
  </div>

  <p class="block">
    De geprinte versie is geprint vanaf deze pagina in de 'Reader modus' van de Internetbrowser, daarom dit deze er anders uit, maar inhoudelijk zijn ze hetzelfde. De geprinte versie bevat de vluchtplannen.
  </p>
  <p class="block">
    Het BHV-plan is beschikbaar via SharePoint -> KVGM-Publiek, zie <a href="https://rphwaalwijk.sharepoint.com/sites/KVGMPubliek/SitePages/BHV-Plan.aspx">hier</a>. De KVGM Coördinator kan dit plan aanpassen, alle andere collega’s kunnen het plan lezen.
  </p>    
</template>

<script setup>
const emit = defineEmits(['page'])
</script>

<style scoped>
img {
    width: 100%;
    max-height: 25vh;
    object-fit: contain;
}
</style>