<template>
  <div
    class="breadcrumb"
    aria-label="breadcrumbs"
  >
    <ul>
      <li><a @click="emit('page', 'start')">Inhoudsopgave</a></li>
      <li class="is-active">
        <a>Organisatie</a>
      </li>
    </ul>
  </div>

  <h3 class="title is-3">
    Organisatie
  </h3>
    
  <h4 class="title is-4">
    Overzicht BHV-ers
  </h4>
  <p class="block">
    In verband met de vele medewerkers die parttime werken en de verspreiding van het personeel over twee panden is het niet mogelijk om 1 a 2 BHV’ers aan te duiden en tevens te zorgen voor een dagelijkse bezetting van de BHV-organisatie. Om die reden is ervoor gekozen om elke medewerker binnen 12 maanden na indiensttreding een BHV-opleiding aan te bieden. 
  </p>

  <h4 class="title is-4">
    Opleidingen en oefeningen BHV
  </h4>
  <p class="block">
    De opleidingen van de BHV-ers worden door de KVGM-coördinator bijgehouden in het opleidingenoverzicht. Jaarlijks verzorgt de KVGM-coördinator de opleidingen in samenwerking met een erkend opleider. 
  </p>
  <p class="block">
    Minimaal één keer per jaar wordt het BHV-plan geoefend, inclusief een ontruiming. Doelstelling is om met elke oefening te leren, hoe men moet handelen bij een mogelijke noodsituatie. Van iedere oefening wordt geregistreerd, welke BHV-ers hebben deelgenomen aan de oefening en wat hun rol hierin was. Tevens worden door de KVGM-Coördinator notulen en actiepunten bijgehouden, die ter verdere verbetering van de BHV oefeningen en handelingen kunnen bijdragen. 
  </p>

  <h4 class="title is-4">
    Rolverdeling
  </h4>
  <p class="block">
    De medewerker die als eerste ter plaatse is bij een BHV-situatie neemt de verantwoordelijkheid en spreekt ook hardop uit dat hij/zij de leiding neemt. Deze medewerker heeft voor deze situatie de rol van BHV-coördinator. 
  </p>
  <p class="block">
    De BHV-coördinator instrueert, afhankelijk van de situatie de andere medewerkers. <br>
    BHV-coördinator blust brand (max 1 fles) en verleent EHBO.
  </p>

  <h4 class="title is-4">
    Taken BHV-coördinator
  </h4>
  <div class="content block">
    <ul>
      <li>Zorg altijd eerst voor de eigen veiligheid</li>
      <li>Neem de leiding en spreek hardop uit naar andere aanwezigen “ik neem de leiding”</li>
      <li>Geef taken aan andere aanwezig BHV’ers</li>
      <li>Verleen EHBO (verplaats het slachtoffer niet)</li>
      <li>Besluit tot (deels)ontruimen</li>
      <li>Draag na aankomst van de hulpverleningsdienst de leiding over en blijf ter beschikking voor ondersteuning</li>
      <li>Wacht bij ongevallen met fatale afloop tot er contact met de politie is geweest alvorens verdere actie wordt genomen. </li>
      <li>Beslis in samenwerking met de brandweer en/of de politie hoe en wanneer de verzamelde medewerkers terug naar de werkplek kunnen dan wel naar huis kunnen gaan. </li>
    </ul>
  </div>

  <h4 class="title is-4">
    Taken Melder
  </h4>
  <div class="content block">
    <ul>
      <li>Zorg altijd eerst voor de eigen veiligheid</li>
      <li>
        Waarschuw na krijgen van de opdracht tot melden onmiddellijk de verantwoordelijke instantie via het algemeen alarmnummer 112:
        <ul>
          <li>De brandweer</li>
          <li>De politie</li>
          <li>De ambulance of andere hulpverleningsinstanties. </li>
        </ul>
      </li>
      <li>
        Voorbeeld van een dergelijke melding:
        <ul>
          <li>Naammelder</li>
          <li>Naambedrijf</li>
          <li>Adreslocatie </li>
          <li>Telefoonnummer</li>
          <li>Brandlocatie</li>
          <li>Duidelijke omschrijving van de situatie</li>
          <li>Aard en omvang van de slachtoffers geven </li>
        </ul>
      </li>
      <li>Aansluitend: melden op de verzamelplaats </li>
    </ul>
  </div>
  <p class="block">
    Bel bij loos alarm, waarbij de brandweer of politie is gealarmeerd, onmiddellijk naar de bewuste partijen met de mededeling ‘loos alarm’. Dit om te voorkomen dat men onnodig uitrukt.
  </p>

  <h4 class="title is-4">
    Taken Ontruimer
  </h4>
  <div class="content block">
    <ul>
      <li>Zorg altijd eerst voor de eigen veiligheid</li>
      <li>
        Ontruim toegewezen afdeling/verdieping, zodra daartoe opdracht gegeven wordt
        <ul>
          <li>Bepaal op basis van de aanwezigheidsborden het aantal aanwezige personen, inclusief namen</li>
          <li>Beoordeel dan of het veilig is om naar de toegewezen afdeling/verdieping toe te gaan.</li>
        </ul>
      </li>
      <li>
        Zorg ervoor dat de aanwezige personeelsleden en derden het pand zo snel mogelijk verlaten en geef hun de instructie te verzamelen op het verzamelpunt
        <ul>
          <li>Loods personeel en bezoekers via de kortste veilige weg naar buiten</li>
          <li>Let erop dat laden en kasten dicht zijn of worden gedaan</li>
          <li>In principe worden geen spullen meegenomen en zijn de deuren dicht (maar niet op slot)</li>
        </ul>
      </li>
      <li>Controleer of er niemand achterblijft, controleer de toiletten, magazijn en andere dienstruimtes</li>
      <li>Sluit ramen </li>
      <li>Geef bij aankomst op verzamelplaats zo snel mogelijk aan BHV-leider door dat de betreffende afdeling/verdieping ontruimd is </li>
      <li>Controleer, op de verzamelplaats aangekomen, samen met de hulpverleners of alle in het pand aanwezige personeelsleden en derden zich aldaar hebben verzameld </li>
      <li>Vraag na bij andere medewerkers of er iemand vermist wordt</li>
    </ul>
  </div>

  <h4 class="title is-4">
    Taken van de overige medewerkers
  </h4>
  <div class="content block">
    <ul>
      <li>Zorg altijd eerst voor de eigen veiligheid</li>
      <li>Meld een calamiteit altijd direct bij een BHV’er</li>
      <li>
        Geef bij de melding de volgende zaken door:
        <ul>
          <li>Plaats van de calamiteit</li>
          <li>Ernst van de calamiteit</li>
          <li>Eventuele gewonden</li>
        </ul>
      </li>
      <li>Wacht aansluitend op verdere instructies</li>
      <li>Indien de BHV-Leider of de Ontruimer meldt dat het pand verlaten dient te worden: schakel dan alle elektrische apparatuur in uw omgeving uit en ga naar de verzamelplaats</li>
      <li>Blijf tijdens en na het incident kalm en voorkom paniek</li>
      <li>Ondersteun collega’s en andere aanwezigen</li>
      <li>Verzamel op de verzamelplaats.</li>
    </ul>
  </div>

  <h4 class="title is-4">
    HR of Management
  </h4>
  <div class="content block">
    <ul>
      <li>Neem bij letselschade contact op met de familie van de betrokkene(n)</li>
      <li>Zorg voor opvang van medewerkers en andere aanwezigen bij de overval (bijvoorbeeld Slachtofferhulp)</li>
    </ul>
  </div>
</template>

<script setup>
const emit = defineEmits(['page'])
</script>

<style scoped>
img {
    width: 100%;
    max-height: 25vh;
    object-fit: contain;
}
</style>