<template>
  <Modal
    :visible="visible"
    title="Aanmelden"
    :dismissable="false"
  >
    <Notification
      v-if="errorMessage"
      class="is-danger"
      dismissable
    >
      {{ errorMessage }}
    </Notification>

    <div class="block">
      <a
        class="button is-fullwidth is-dark"
        :href="singleSignOnMicrosoftUrl"
      >
        <span class="icon">
          <FontAwesomeIcon :icon="faMicrosoft" />
        </span>
        <span>Aanmelden met Microsoft</span>
      </a>
    </div>

    <div class="block">
      <div class="columns is-align-items-center is-mobile">
        <div class="column">
          <hr>
        </div>
        <div class="column is-narrow">
          of
        </div>
        <div class="column">
          <hr>
        </div>
      </div>
    </div>

    <form
      class="block"
      @submit.prevent="submit"
    >
      <input
        ref="nameInputNode"
        v-model="username"
        class="input"
        type="email"
        placeholder="Inlognaam (e-mail)"
        autocomplete="username"
      >

      <br>

      <input
        v-model="password"
        class="input mt-5"
        type="password"
        placeholder="Wachtwoord"
        autocomplete="current-password"
      >

      <br>

      <label class="checkbox mt-5">
        <input
          v-model="rememberMe"
          type="checkbox"
        >
        Onthoud mij
      </label>

      <div class="buttons is-right mt-5">
        <button
          class="button is-pulled-right"
          :disabled="!canSubmit"
          type="submit"
          @click="submit"
        >
          <span>Aanmelden</span>
          <span
            v-if="validating"
            class="icon"
          >
            <FontAwesomeIcon
              :icon="faSpinner"
              spin
            />
          </span>
        </button>
      </div>
    </form>
  </Modal>
</template>

<script setup>
import { ref, computed, watch, nextTick } from 'vue'
import store from '../store'
import Modal from './common/Modal.vue'
import Notification from './common/Notification.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons'

const props = defineProps({
  visible: { type: Boolean, default: false },
  validating: { type: Boolean, default: false }
})

const emit = defineEmits(['update:visible', 'submit'])

const username = ref(null)
const password = ref(null)
const rememberMe = ref(store.getters['login/rememberMe'])
const nameInputNode = ref(null)

const canSubmit = computed(() => (username.value || '').trim() !== '' && (password.value || '').trim() !== '' && !props.validating)
const singleSignOnMicrosoftUrl = computed(() => store.getters['login/loginWithMicrosoftUrl'])
const errorMessage = computed(() => store.getters['login/errorMessage'])

const submit = () => {
  if (!canSubmit.value) {
    return
  }

  emit('submit', {
    username: username.value,
    password: password.value,
    rememberMe: rememberMe.value,
  })
}

watch(
  () => props.visible,
  visible => {
    if (visible) {
      username.value = null
      password.value = null
      rememberMe.value = store.getters['login/rememberMe']
      nextTick(() => nameInputNode.value.focus())
    }
  }
)
</script>

