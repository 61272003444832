<template>
  <div class="box">
    <div class="level is-mobile">
      <div class="level-left">
        <h2 class="title is-4">
          <span>{{ location.name }} </span>
          <a
            :class="{ 'has-text-light': !favorite, 'has-text-info': favorite }"
            @click="emit('toggle-favorite')"
          >
            <span class="icon ml-2">
              <FontAwesomeIcon :icon="faStar" />
            </span>
          </a>
        </h2>
      </div>

      <div class="level-right">
        <button
          class="button"
          @click="addModalVisible = true"
        >
          <span class="icon is-small">
            <FontAwesomeIcon :icon="faUserPlus" />
          </span>
          <span>Aanmelden</span>
        </button>
      </div>
    </div>

    <transition-group
      name="persons"
      tag="div"
    >
      <div
        v-for="person of persons"
        :key="person.id"
        class="mb-2"
      >
        <PersonAttendance
          :name="person.name"
          :is-enabled="person.enabled"
          :is-guest="person.guest"
          can-leave
          @leave="leave(person)"
        />      
      </div>
    </transition-group>
    
    <AddModal
      v-model:visible="addModalVisible"
      :location-id="location.id"
    />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import store from '../store'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserPlus, faStar } from '@fortawesome/free-solid-svg-icons'
import AddModal from './AddModal.vue'
import PersonAttendance from './PersonAttendance.vue'

const props = defineProps({
  location: { type: Object, required: true },
  favorite: { type: Boolean, default: false }
})

const emit = defineEmits(['toggle-favorite'])

const transits = ref([])
const addModalVisible = ref(false)

const persons = computed(() => props.location.persons)

const leave = person => {
  let transit = transits.value.find(t => t.person === person)
  if (transit) {
      window.clearTimeout(transit.timeoutId)
  } else {
      transit = { timeoutId: null, person }
      transits.value.push(transit)
  }

  const timeoutId = window.setTimeout(() => {
      person.active = person.enabled

      store.dispatch('presence/leave', {
          person_id: person.id,
          location_id: props.location.id
      })

      const index = transits.value.findIndex(t => t === transit)
      transits.value.splice(index, 1)
  }, 250)

  transit.timeoutId = timeoutId
}
</script>

<style scoped>
.persons-move {
  transition: transform .3s;
}

.persons-enter-active, .persons-leave-active {
  transition: all .3s;
}
.persons-enter-from, .persons-leave-to {
  opacity: 0;
  transform: scale(.8);
}

.divider-overlay {
  font-size: 80%;
  display: block;
  margin-top: calc(-.75em);
}

.divider-overlay span {
  font-weight: bold;
  padding-left: 1ex;
  padding-right: 1ex;
  display: inline-block;
  background-color: white;
  border: 1px solid white;

  text-transform: uppercase;
}

.person {
  border: 1px solid #80808033;
}
</style>
