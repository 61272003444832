<template>
  <div
    class="bhv-container has-background-white-ter "
    :class="{ 'is-hidden': !visible }"
  >
    <section
      class="section"
      style="height: 100%;"
    >
      <div
        class="container"
        style="height: 100%;"
      >
        <div
          class="is-flex is-flex-direction-column"
          style="height: 100%;"
        >
          <!-- title -->
          <div class="level">
            <div class="level-left">
              <h1 class="title is-1">
                BHV-plan
              </h1>
            </div>
            <div class="level-right">
              <button
                class="delete is-large"
                @click="emit('update:visible', false)"
              />
            </div>
          </div>

          <!-- content -->
          <div
            class="box is-flex-grow-1"
            style="height: 100%; overflow-y: auto;"
          >
            <component
              :is="components[page]"
              @page="page = $event"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import StartPage from './bhv-plan/StartPage.vue'
import VerantwoordingPage from './bhv-plan/VerantwoordingPage.vue'
import BasisgegevensPage from './bhv-plan/BasisgegevensPage.vue'
import TechnischeGegevensPage from './bhv-plan/TechnischeGegevensPage.vue'
import OrganisatiePage from './bhv-plan/OrganisatiePage.vue'
import MaterialenPage from './bhv-plan/MaterialenPage.vue'
import SpecifiekeSituatiesPage from './bhv-plan/SpecifiekeSituatiesPage.vue'
import RapportagesPage from './bhv-plan/RapportagesPage.vue'

const props = defineProps({
    visible: { type: Boolean, required: true }
})

const emit = defineEmits(['update:visible'])
const page = ref('start')

const components = {
    'start': StartPage,
    'verantwoording': VerantwoordingPage,
    'basisgegevens': BasisgegevensPage,
    'technische-gegevens': TechnischeGegevensPage,
    'organisatie': OrganisatiePage,
    'materialen': MaterialenPage,
    'specifieke-situaties': SpecifiekeSituatiesPage,
    'rapportages': RapportagesPage
}

watch(
    () => props.visible,
    visible => {
        // reset to start page when closing
        if (!visible) {
            page.value = 'start'
        }
    }
)

</script>

<style scoped>
.bhv-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}
</style>