<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div
    v-if="!dismissed"
    class="notification"
  >
    <button
      v-if="dismissable"
      class="delete"
      @click="dismissed = true"
    />
    <slot />
  </div>
</template>

<script setup>
import { ref } from "vue";

defineProps({
  dismissable: { type: Boolean, default: false },
});

const dismissed = ref(false);
</script>
