import { del, post } from '../../services/Fetch'

const state = {
    updating: false
}

const getters = {
    updating: state => state.updating
}

const mutations = {
    setUpdating(state, updating) {
        state.updating = updating
    }
}

const actions = {
    removePerson ({ rootGetters, commit }, { personId }) {
        commit('setUpdating', true)

        const url = rootGetters['settings/apiBaseUrl'] + '/api/admin/person/' + encodeURIComponent(personId)

        del(url)
            .then(response => {
                commit('setUpdating', false)
                return response
            })
            .catch(e => {
                commit('setUpdating', false)
                throw e
            })
    },
    addPerson ({ rootGetters, commit }, { name }) {
        commit('setUpdating', true)

        const url = rootGetters['settings/apiBaseUrl'] + '/api/admin/person'

        return post (url, JSON.stringify({ name }))
            .then(response => {
                commit('setUpdating', false)

                return response
            })
            .catch(e => {
                commit('setUpdating', false)
                throw e
            })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
