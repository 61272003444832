const state = () => ({
    // see .env and .env.local
    // eslint-disable-next-line no-undef
    apiBaseUrl: process.env.VUE_APP_API_BASE_URL,
    favoriteLocationId: parseInt(window.localStorage.getItem('favorite-location-id')) || null
})

const getters = {
    apiBaseUrl: (state) => state.apiBaseUrl,
    favoriteLocationId: (state) => state.favoriteLocationId
}

const mutations = {
    setFavoriteLocationId (state, locationId) {
        state.favoriteLocationId = locationId

        if (locationId) {
          window.localStorage.setItem('favorite-location-id', String(locationId))
        } else {
          window.localStorage.removeItem('favorite-location-id')
        }
    }
}

const actions = {
    updateFavoriteLocationId({ commit }, locationId) {
        commit('setFavoriteLocationId', locationId)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
