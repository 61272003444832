import { createApp } from 'vue'
import App from './App.vue'
import './assets/main.scss'
import store from './store'

const params = new URLSearchParams(window.location.search)
const ssoToken = params.has('sso-token') ? params.get('sso-token') : null
if (params.has('sso-token')) {
  params.delete('sso-token')

  const url = new URL(window.location)
  url.search = params
  
  // remove sso-token 
  window.history.replaceState(null, '', url)
}

const ssoError = params.has('sso-error') ? params.get('sso-error') : null
if (ssoError) {
  params.delete('sso-error')

  const url = new URL(window.location)
  url.search = params
  
  // remove sso-error
  window.history.replaceState(null, '', url)
}

const app = createApp(App, { ssoToken, ssoError })
app.mount('#app')
app.use(store)
