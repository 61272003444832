<template>
  <div 
    class="box is-shadowless person p-2"
    :class="{ 'has-background-light': disabled }"
  >
    <div class="level is-mobile">
      <div
        class="level-left"
        :class="{ 'has-text-grey': !isEnabled }"
      >
        <span>{{ name }}</span>
        <span
          v-if="isGuest"
          class="tag is-info ml-2"
        >Gast</span>
      </div>
      <div 
        v-if="canLeave &amp;&amp; !disabled"
        class="level-right"
      >
        <button 
          class="button"
          @click="emit('leave')"
        >
          <span class="icon">
            <FontAwesomeIcon :icon="faSignOutAlt" />
          </span>
        </button>
      </div>
      <div 
        v-if="canEnter &amp;&amp; !disabled"
        class="level-right"
      >
        <button 
          class="button"
          @click="emit('enter')"
        >
          <span class="icon">
            <FontAwesomeIcon :icon="faSignInAlt" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSignInAlt,faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

defineProps({
  name: { type: String, default: null },
  isGuest: { type: Boolean, default: false },
  isEnabled: { type: Boolean, default: true },
  canLeave: { type: Boolean, default: false },
  canEnter: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false }
});

const emit = defineEmits(['enter', 'leave'])
</script>
