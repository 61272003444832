import { UnauthorizedException } from "../exception"
import store from '../store'

function doRequest(method, url, body) {
    return new Promise((resolve, reject) => {
        const params = { method, mode: 'cors', credentials: 'include' }
        if (body !== undefined) {
            params.body = body
        }

        if (store.getters['login/loggedIn']) {
            params.headers = { 'Authorization': 'Bearer ' + store.getters['login/token'] }
        }

        window.fetch(url, params)
            .then(response => {
                if (response.status === 401) {
                    reject(new UnauthorizedException())
                }

                if (response.status === 204) {
                    return null
                }

                if (response.headers.has('Content-Type')) {
                    if (response.headers.get('Content-Type') === 'application/json') {
                        return response.json()
                    }
                }        
        
                return response
            })
            .then(response => resolve(response))
    })
}

export const get = url => doRequest('GET', url)
export const post = (url, body) => doRequest('POST', url, body)
export const put = (url, body) => doRequest('PUT', url, body)
export const del = url => doRequest('DELETE', url)
