<template>
  <div
    class="breadcrumb"
    aria-label="breadcrumbs"
  >
    <ul>
      <li class="is-active">
        <a>Inhoudsopgave</a>
      </li>
    </ul>
  </div>

  <div class="block">
    <p><img src="../../assets/bhv-plan/verzamelplaats-zinkerweg.webp"></p>
    <p class="has-text-centered">
      <em>Verzamelplaats grasstrook Zinkerweg</em>
    </p>
  </div>

  <div class="block">
    <h3 class="title is-3">
      Inhoudsopgave
    </h3>
        
    <div class="content">
      <ul>
        <li><a @click="emit('page', 'verantwoording')">Verantwoording BHV-plan</a></li>
        <li><a @click="emit('page', 'basisgegevens')">Basisgegevens</a></li>
        <li><a @click="emit('page', 'technische-gegevens')">Technische gegevens</a></li>
        <li><a @click="emit('page', 'organisatie')">Organisatie</a></li>
        <li><a @click="emit('page', 'materialen')">Materialen</a></li>
        <li><a @click="emit('page', 'specifieke-situaties')">Specifieke situaties</a></li>
        <li><a @click="emit('page', 'rapportages')">Rapportages</a></li>
      </ul>
    </div>
  </div>

  <div class="block">
    <p>
      Heb je vragen? Neem dan contact op met de KVGM-Coördinator.
    </p>
  </div>
</template>

<script setup>
const emit = defineEmits(['page'])
</script>

<style scoped>
img {
    width: 100%;
    max-height: 25vh;
    object-fit: contain;
}
</style>