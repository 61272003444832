import { get, put, del, post } from '../../services/Fetch'

const FALLBACK_RELOAD_TIMEOUT_MS = 1000

const state = () => ({
    presence: null,
    loading: false,
    updateTimeoutId: null,
    eventSource: null,
    persons: [],
    loadingPersons: false
})

const getters = {
    presence: state => state.presence,
    loading: state => state.loading,
    persons: state => state.persons,
    loadingPersons: state => state.loadingPersons
}

const mutations = {
    clearPresence () {
        state.presence = null
    },
    setPresence (state, { presence }) {
        state.presence = presence
    },
    setLoading (state, loading) {
        state.loading = loading
    },
    setUpdateTimeoutId (state, id) {
        state.updateTimeoutId = id
    },
    clearUpdateTimeoutId (state) {
        if (state.updateTimeoutId !== null) {
            window.clearTimeout(state.updateTimeoutId)
        }
        state.updateTimeoutId = null
    },
    setEventSource(state, eventSource) {
      state.eventSource = eventSource
    },
    closeEventSource(state) {
      if (state.eventSource) {
        state.eventSource.close()
      }
      state.eventSource = null
    },
    personLeave (state, { personId }) {
        if (!state.presence) {
            return
        }

        for (const location of state.presence.locations) {
            const index = location.persons.findIndex(p => p.id === personId)
            if (index !== -1) {
                location.persons.splice(index, 1)
                break
            }
        }
    },
    personEnter (state, { person, locationId }) {
        if (!state.presence) {
            return
        }

        for (const location of state.presence.locations) {
            const index = location.persons.findIndex(p => p.id === person.id)
            if (index !== -1 && location.id !== locationId) {
                location.persons.splice(index, 1)                
            } else if (index === -1 && location.id === locationId) {
                location.persons.push(person)
            }
        }
    },
    setPersons (state, persons) {
        state.persons = persons
    },
    setLoadingPersons (state, loading) {
        state.loadingPersons = loading
    }
}

const actions = {
    loadPresence ({ rootGetters, commit }) {
        commit('clearUpdateTimeoutId')
        commit('setLoading', true)

        const url = rootGetters['settings/apiBaseUrl'] + '/api/presence'

        get(url)
            .then(response => response.json())
            .then(data => {
                const presence = {
                    locations: data.locations.map(location => {
                        return {
                            id: location.id,
                            name: location.name,
                            persons: location.persons.map(person => {
                                return Object.assign(
                                    {},
                                    person,
                                    { active: person.present, enabled: person.present }
                                )
                            })
                        }
                    })
                }

                commit('setLoading', false)
                commit('setPresence', { presence })

                return presence
            })
            .catch(e => {
                commit('setLoading', false)

                console.error('error:', e)

                throw e
            })
    },

    clearPresence ({commit}) {
      commit('clearUpdateTimeoutId')
      commit('setPresence', { presence: null })
      commit('setLoading', false)
    },

    updatePresence ({ rootGetters, commit, dispatch }, { person_id, present }) {
        const url = rootGetters['settings/apiBaseUrl']
            + '/api/person/'
            + encodeURIComponent(person_id)
            + '/'
            + (present ? 'present' : 'absent')

        return put(url)
            .then(() => {
                // ensure the presence is updated, in case the updated event is not received
                const timeoutId = window.setTimeout(() => dispatch('loadPresence'), FALLBACK_RELOAD_TIMEOUT_MS)
                commit('setUpdateTimeoutId', timeoutId)
            })
    },

    enter ({ rootGetters, commit, dispatch }, { person, location_id }) {
        const url = rootGetters['settings/apiBaseUrl'] 
            + '/api/person/'
            + encodeURIComponent(person.id) 
            + '/location/'
            + encodeURIComponent(location_id)

        return put(url)
            .then(() => {
                commit('personEnter', { person, locationId: location_id })

                // ensure the presence is updated, in case the updated event is not received
                const timeoutId = window.setTimeout(() => dispatch('loadPresence'), FALLBACK_RELOAD_TIMEOUT_MS)
                commit('setUpdateTimeoutId', timeoutId)
            })
    },

    enterGuest ({ rootGetters, commit, dispatch }, { name, location_id }) {
        const url = rootGetters['settings/apiBaseUrl'] 
            + '/api/location/' 
            + encodeURIComponent(location_id)
            + '/guest'        

        return post(url, JSON.stringify({ name }))
            .then(() => {
                // ensure the presence is updated, in case the updated event is not received
                const timeoutId = window.setTimeout(() => dispatch('loadPresence'), FALLBACK_RELOAD_TIMEOUT_MS)
                commit('setUpdateTimeoutId', timeoutId)
            })
    },

    leave ({ rootGetters, commit, dispatch }, { person_id }) {
        const url = rootGetters['settings/apiBaseUrl'] + '/api/person/' + encodeURIComponent(person_id) + '/location'

        return del(url)
            .then(() => {
                commit('personLeave', { personId: person_id })

                // ensure the presence is updated, in case the updated event is not received
                const timeoutId = window.setTimeout(() => dispatch('loadPresence'), FALLBACK_RELOAD_TIMEOUT_MS)
                commit('setUpdateTimeoutId', timeoutId)
            })
    },

    loadPersons ({ rootGetters, commit }) {
        const url = rootGetters['settings/apiBaseUrl'] + '/api/person'

        commit ('setLoadingPersons', true)

        return get(url)
            .then(response => response.json())
            .then(data => {
                commit ('setLoadingPersons', false)
                commit('setPersons', data.persons)
            })
            .catch(e => {
                commit ('setLoadingPersons', false)
                throw e
            })
    },

    registerForUpdates ({ commit, rootGetters, dispatch }) {
        const source = new EventSource(rootGetters['settings/apiBaseUrl'] + '/event')

        source.addEventListener('presence', function() {
            dispatch('loadPresence')
        })

        commit('setEventSource', source)
    },

    unregisterForUpdates({ commit }) {
      commit('closeEventSource')
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
