<template>
  <Modal 
    :visible="visible"
    title="Personen beheren"
    @update:visible="emit('update:visible', $event)"
  >
    <span 
      v-if="loadingPersons"
      class="icon"
    >
      <FontAwesomeIcon
        :icon="faSpinner"
        spin
      />
    </span>

    <template v-if="!loadingPersons">
      <form @submit.prevent="addPerson">
        <div class="columns">
          <div class="column">
            <div class="field">
              <label
                :for="elementIds.newPersonName"
                class="label"
              >Naam</label>
              <div class="control">
                <input 
                  :id="elementIds.newPersonName" 
                  v-model="newPerson.name" 
                  class="input" 
                  type="text" 
                  :disabled="updating"
                >
              </div>
            </div>
          </div>        
          <div class="column is-narrow">
            <div class="field">
              <div class="control">
                <label class="label"><span class="is-invisible">&#xa0;</span></label>
                <button
                  class="button is-primary"
                  type="submit"
                  :disabled="updating || loadingPersons || !canAddPerson"
                >
                  <span class="icon"><FontAwesomeIcon :icon="faPlus" /></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
        
      <hr>

      <div 
        v-for="person of persons" 
        :key="person.id"
        class="columns"
        :class="{ 'has-background-light': evenPersons.includes(person) }"
      >
        <div class="column">
          <div class="field">
            <div class="control">
              <input
                class="input is-static"
                :value="person.name"
              >
            </div>
          </div>          
        </div>
        <div class="column is-narrow">
          <div class="field">
            <div class="control">
              <button
                class="button is-danger"
                :disabled="updating || loadingPersons"
                @click="removePerson(person)"
              >
                <span class="icon"><FontAwesomeIcon :icon="faTimes" /></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import store from '../store'
import Modal from './common/Modal.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTimes, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { generateId } from '../util/id'

const props = defineProps({
  visible: { type: Boolean, required: true }
})

const emit = defineEmits(['update:visible'])

const newPerson = ref({ name: null })

const elementIds = { newPersonName: generateId() }

const updating = computed(() => store.getters['admin/updating'])
const persons = computed(() => store.getters['presence/persons'])
const loadingPersons = computed(() => store.getters['presence/loadingPersons'])
const evenPersons = computed(() => {
  let i = 0
  const even = []
  for (const person of persons.value) {
    ++i
    if (i % 2 === 0) {
      even.push(person)
    }
  }

  return even
})
const canAddPerson = computed(() => newPerson.value.name)

const removePerson = person => {
  store.dispatch('admin/removePerson', { personId: person.id })
}
const addPerson = () => {
  store.dispatch('admin/addPerson', { name: newPerson.value.name })
    .then(person => {
      if (person.id) { // person add successful
        newPerson.value.name = null
      }
    })
}

watch(
  () => updating.value,
  updating => {
    if (!updating) {
      store.dispatch('presence/loadPersons') // reload after update
    }
  }
)
watch(
  () => props.visible,
  visible => {
    if (visible) {
      store.dispatch('presence/loadPersons')
    }
  },
  { immediate: true }
)
</script>
