import { createStore } from 'vuex'
import presence from './modules/presence'
import settings from './modules/settings'
import login from './modules/login'
import admin from './modules/admin'



export default createStore({
  modules: {
      presence,
      settings,
      login,
      admin
  }
})