<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="tabs">
    <ul>
      <li
        v-for="option of options"
        :key="option.value"
        :class="{ 'is-active': modelValue === option.value }"
      >
        <a @click="emit('update:modelValue', option.value)">
          <span
            v-if="option.icon"
            class="icon is-small"
          >
            <FontAwesomeIcon :icon="option.icon" />
          </span>
          <span>{{ option.label }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

defineProps({
  modelValue: { type: String, default: null },
  options: { type: Array, default: () => [] },
})

const emit = defineEmits(['update:modelValue'])
</script>
