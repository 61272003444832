import { post } from '../../services/Fetch'

const AUTO_EXTEND_TIMEOUT_MS = 1000 * 60 * 10

const autoExtendSession = (baseUrl, state, renewalToken) => {
    if (state.token) {
        return post(
            baseUrl + '/api/session/extend',
            JSON.stringify({ renewal_token: renewalToken }
        ))
        .then(() => {
            window.setTimeout(
                () => autoExtendSession(baseUrl, state, renewalToken),
                AUTO_EXTEND_TIMEOUT_MS
            )
        })
    }
}

const state = () => ({
    loggedIn: false,
    isAdmin: false,
    token: null,
    rememberMe: window.localStorage.getItem('remember-me') || false,
    rememberMeToken: window.localStorage.getItem('remember-me-token'),
    errorMessage: null
})

const getters = {
    loggedIn: state => state.loggedIn,
    isAdmin: state => state.isAdmin,
    token: state => state.token,
    rememberMe: state => state.rememberMe,
    rememberMeToken: state => state.rememberMeToken,    
    // eslint-disable-next-line no-undef
    loginWithMicrosoftUrl: () => process.env.VUE_APP_API_BASE_URL + '/api/single-sign-on/microsoft',
    errorMessage: state => state.errorMessage
}

const mutations = {
    login (state, { token, isAdmin }) {
        state.loggedIn = true
        state.isAdmin = isAdmin
        state.token = token
        state.errorMessage = null
    },
    logout (state) {
        state.loggedIn = false
        state.isAdmin = false
        state.token = null
    },
    setRememberMe (state, rememberMe) {
        state.rememberMe = rememberMe

        if (rememberMe) {
          window.localStorage.setItem('remember-me', 'true')
        } else {
          window.localStorage.removeItem('remember-me')
        }
    },
    setRememberMeToken (state, rememberMeToken) {
      state.rememberMeToken = rememberMeToken

      if (rememberMeToken) {
        window.localStorage.setItem('remember-me-token', rememberMeToken)
      } else {
        window.localStorage.removeItem('remember-me-token')
      }
    },
    setErrorMessage (state, message) {
        state.errorMessage = message
    },
    clearErrorMessage (state) {
        state.errorMessage = null
    }
}

const actions = {
    login ({ state, commit, rootGetters }, { username, password, rememberMe }) {
        commit('setRememberMe', rememberMe)

        return post(rootGetters['settings/apiBaseUrl'] + '/api/login', JSON.stringify({ username, password, remember_me: rememberMe }))
            .then(({ session, remember_me_token, is_admin }) => {
                const { token, renewal_token } = session
                commit('login', { token, isAdmin: is_admin })
                commit('setRememberMeToken', remember_me_token)
                window.setTimeout(
                    () => autoExtendSession(rootGetters['settings/apiBaseUrl'], state, renewal_token),
                    AUTO_EXTEND_TIMEOUT_MS
                )
            })
            .catch(() => {
                commit('setErrorMessage', 'Aanmelden mislukt')
            })
    },
    loginSsoToken ({ state, commit, rootGetters}, { ssoToken }) {
        return post(rootGetters['settings/apiBaseUrl'] + '/api/login-sso-token', JSON.stringify({ token: ssoToken, remember_me: !!state.rememberMeToken }))
            .then(({ session, remember_me_token, is_admin }) => {
                const { token, renewal_token } = session
                commit('login', { token, isAdmin: is_admin })
                commit('setRememberMeToken', remember_me_token)
                window.setTimeout(
                    () => autoExtendSession(rootGetters['settings/apiBaseUrl'], state, renewal_token),
                    AUTO_EXTEND_TIMEOUT_MS
                )
            })
    },
    loginRememberMe ({ state, commit, rootGetters }) {
        if (!state.rememberMeToken) {
            return Promise.resolve(null)
        }

        return post(rootGetters['settings/apiBaseUrl'] + '/api/login-remember-me', JSON.stringify({ token: state.rememberMeToken }))
            .then(({ session, remember_me_token, is_admin }) => {
                const { token, renewal_token } = session
                commit('login', { token, isAdmin: is_admin })
                commit('setRememberMeToken', remember_me_token)
                window.setTimeout(
                    () => autoExtendSession(rootGetters['settings/apiBaseUrl'], state, renewal_token),
                    AUTO_EXTEND_TIMEOUT_MS
                )
          })
    },
    logout ({ commit, rootGetters }) {
        return post(rootGetters['settings/apiBaseUrl'] + '/api/logout')
            .then(() => {
              commit('logout', false)
              commit('setRememberMeToken', null)
            })
    },
    loggedIn ({ commit }) {
        commit('setLoggedIn', true)
        commit('clearErrorMessage')
    },
    loggedOut ({ commit }) {
        commit('setLoggedIn', false)
    },
    setErrorMessage ({ commit }, message) {
        commit('setErrorMessage', message)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
