<template>
  <div class="level is-pulled-right is-mobile">
    <div>
      <button
        class="button"
        @click="emit('show-bhv-plan')"
      >
        <span class="icon is-small">
          <FontAwesomeIcon :icon="faFileLines" />
        </span>
        <abbr>BHV-plan</abbr>
      </button>
    </div>

    <button
      v-if="isAdmin"
      class="button ml-3"
      @click="emit('manage-persons')"
    >
      <span class="icon">
        <FontAwesomeIcon :icon="faUsersCog" />
      </span>
    </button>

    <button
      v-if="isLoggedIn"
      class="button ml-3"
      @click="emit('sign-out')"
    >
      <span class="icon">
        <FontAwesomeIcon :icon="faSignOutAlt" />
      </span>
    </button>
  </div>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSignOutAlt, faUsersCog, faFileLines } from '@fortawesome/free-solid-svg-icons'

defineProps({
  darkTheme: { type: Boolean, default: false },
  isLoggedIn: { type: Boolean, default: false },
  isAdmin: { type: Boolean, default: false }
})

const emit = defineEmits(['update:darkTheme', 'manage-persons', 'sign-out', 'show-bhv-plan'])
</script>
